"use strict";

import { FullStory, init } from "@fullstory/browser";

const FullStoryPort = {
  add: function (app, orgId) {
    if (orgId) {
      init({ orgId: orgId });

      app.ports.fullStoryPortInit.subscribe(function (practice) {
        FullStory("setIdentity", {
          anonymous: true,
          properties: {
            practice_id: practice.id,
            practice_name: practice.name,
            practice_email: practice.email,
          },
        });
      });

      app.ports.fullStoryPortIdentify.subscribe(function (onlineEnrollmentId) {
        FullStory("setIdentity", {
          uid: onlineEnrollmentId,
          properties: {
            online_enrollment_id: onlineEnrollmentId,
          },
        });
      });

      app.ports.fullStoryPortStopCapturing.subscribe(function () {
        FullStory("shutdown");
      });
    }
  },
};

module.exports = FullStoryPort;
