const AmplitudePort = {
  add: function (app, key) {
    if (key) {
      window.setupAmplitude(key);
    }

    app.ports.amplitudePortLogEvent.subscribe(function (event) {
      if (!window.amplitude) {
        return;
      }

      window.amplitude
        .getInstance()
        .logEvent(event.type, Object.assign(event.payload, { app: "member" }));
    });

    app.ports.amplitudePortPropertiesEvent.subscribe(function (event) {
      if (!window.amplitude) {
        return;
      }

      window.amplitude.getInstance().setUserId(event.userId);
      window.amplitude.getInstance().setUserProperties(event.properties);
      app.ports.amplitudePortPropertiesSet.send(null);
    });

    app.ports.amplitudePortShutdown.subscribe(function () {
      if (!window.amplitude) return;

      window.amplitude.getInstance().setUserId(null);
      window.amplitude.getInstance().regenerateDeviceId();
    });
  },
};

module.exports = AmplitudePort;
